import 'bootstrap/dist/css/bootstrap.min.css';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"
import '@/styles/styles.scss'; // 引入全域樣式

const app = createApp(App);
app.use(router);
app.use(ElementPlus);
const defaultTitle = '普惠科技'


// 在每次路由切換前執行
router.beforeEach((to, from, next) => {
    const pageTitle = to.meta.title || ''
    document.title = pageTitle ? `${pageTitle}-${defaultTitle}` : defaultTitle

    // 從路由對象中獲取額外的 class
    const bodyClasses = to.meta.bodyClasses

    // 如果有額外的 class，則將它加到 body 的 class 中
    if (bodyClasses && Array.isArray(bodyClasses)) {
        bodyClasses.forEach(className => {
            document.body.classList.add(className)
        })
    } else {
        // 如果沒有，則確保移除之前的任何額外的 class
        document.body.className = ''
    }

    next()
})

app.mount('#app');