<!-- src/components/Nav.vue -->
<template>
  <nav class="navbar navbar-expand-md fixed-top nav-big-wrapper">
    <div class="container-fluid container-md mx-auto">
      <div class="nav-brand-wrapper">
        <router-link to="/" class="navbar-brand navbar-nav"><img class="logo" src="../assets/logo.png" /></router-link>
        <div class="nav-com-name">
          普惠科技
        </div>
      </div>


      <div class="collapse navbar-collapse justify-content-end">

        <ul class="navbar-nav">
          <!-- <li class="nav-item dropdown dropdown-left fadeup">
            <a class="nav-link" val="系統名稱" href="#"><span class="title-main">系統名稱A</span><span class='caret'></span></a>

            <ul class="navbar-nav">
              <li class="hover_r_sider nav-item">
                <a class="nav-link" href="#">系統分類名稱1</a>
              </li>
              <li class="hover_r_sider nav-item">
                <a class="nav-link" href="#">系統分類名稱2</a>
              </li>
              <li class="hover_r_sider nav-item">
                <a class="nav-link" href="#">系統分類名稱3</a>
              </li>
            </ul>
          </li> -->

          <li class="nav-item dropdown dropdown-left fadeup">
            <router-link to="/" class="nav-link d-inline-block">
              <i class="bi bi-person-fill me-1"></i><span class="title-main">OO公司 - OOO董事長</span>
            </router-link>
            <button type="button" class="btn btn-light btn-sm rounded-pill text-warning fw-bold d-inline-block" href="#" data-bs-toggle="modal" data-bs-target="#memberLoginModal">
              <span class="title-main">缺少檔案，尚未開始審核</span>
            </button>
          </li>
          <li class="nav-item dropdown dropdown-left fadeup">
            <button type="button" class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#memberLoginModal">
              <span class="title-main">會員登入</span>
            </button>
          </li>

          <li class="nav-item dropdown dropdown-left fadeup">
            <router-link to="/" class="nav-link">
              <span class="title-main">線上協助</span>
            </router-link>
          </li>

        </ul>
        <!-- /.nav-1 -->
      </div>



      <div class="small-btn-box">
        <ul class="list-inline">
          <!-- <li class="list-inline-item">
                    <a href="#">
                        <i class="bi bi-cart icon"></i>
                    </a>
                </li> -->
        </ul>

        <a class="navbar-toggler toggler-spring"><span class="navbar-toggler-icon"></span></a>
      </div>
      <!-- /.nav-3 -->

    </div>
  </nav>
  <memberLogin />

</template>

<script>
import memberLogin from './modal/memberLogin.vue'

export default {
  name: 'mainNavbar',

  components: {
    memberLogin
  },
}
</script>