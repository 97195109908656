<!-- src/components/Nav.vue -->
<template>
  <div class="footer_content">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="list-unstyled cominfo-list">
            <li class="list-item tel"><label>電話</label>
              <a href="tel:"></a>
            </li>
            <!-- <li class="list-item fax"><label>傳真</label>
                        
                    </li> -->
            <li class="list-item add">
              <label>地址</label> <a href="https://www.google.com/maps?q=台北市">台北市</a>
            </li>
            <li class="list-item mail">
              <label>Email</label> <a href="mailto:service@mail.com">service@mail.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="copyrights-info">
              <div class="coinfo-box">
                <div class="copyrights">Copyright © 普惠科技 All Rights Reserved.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mainFooter'  
}
</script>