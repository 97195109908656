<template>
  <header class="header">
    <mainNavbar />
    <mainBanner />
  </header>
  <main class="main" id="main">
    <div class="container">
      <router-view />
    </div>
  </main>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  <footer class="footer">
    <mainFooter />
  </footer>
</template>

<script>
import mainNavbar from './components/mainNavbar.vue'
import mainBanner from './components/mainBanner.vue'
import mainFooter from './components/mainFooter.vue'

export default {
  name: 'App',
  components: {
    mainNavbar,
    mainBanner,
    mainFooter
  }
}
</script>