<!-- src/steps/Step1.vue -->
<template>
  <div class="container-lg container-fluid h-100 position-relative">
    <div class="row justify-content-center h-100">
      <div class="content col-12 col-sm-10 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
        <div class="card bg-white-80 shadow p-2 p-xl-3 border-0 mt-3 mt-lg-4 mt-xl-5">
          <div class="card-body">
            <h5 class="card-title text-black fs-4 fw-bold text-center mb-4">會員註冊</h5>

            <!-- Nav tabs -->
            <ul class="nav nav-pills step disabled" id="regisTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="identity-tab" data-bs-toggle="tab" data-bs-target="#identity"
                  type="button" role="tab" aria-controls="identity" aria-selected="true"><span
                    class="num">1</span>選擇身分</button>
              </li>
              <li class="nav-item space-fill"></li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="service-tab" data-bs-toggle="tab" data-bs-target="#service" type="button"
                  role="tab" aria-controls="service" aria-selected="false"><span class="num">2</span>服務條款</button>
              </li>
              <li class="nav-item space-fill"></li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="verify-tab" data-bs-toggle="tab" data-bs-target="#verify" type="button"
                  role="tab" aria-controls="verify" aria-selected="false"><span class="num">3</span>資料驗證</button>
              </li>
              <li class="nav-item space-fill"></li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="accountFile-tab" data-bs-toggle="tab" data-bs-target="#accountFile"
                  type="button" role="tab" aria-controls="accountFile" aria-selected="false"><span
                    class="num">4</span>建立帳號</button>
              </li>
              <li class="nav-item space-fill" v-if="memberKind === 'company'"></li>
              <li class="nav-item" role="presentation" v-if="memberKind === 'company'">
                <button class="nav-link" id="company-tab" data-bs-toggle="tab" data-bs-target="#company" type="button"
                  role="tab" aria-controls="company" aria-selected="false"><span class="num">5</span>公司資料</button>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content needs-validation" novalidate>
              <div class="tab-pane fade show active" id="identity" role="tabpanel" aria-labelledby="identity-tab"
                tabindex="0">
                <div class="row gx-0 gy-3 gy-lg-4 mb-4">
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="memberKind" id="memberKind1" value="company"
                          v-model="memberKind" @change="memberKindChange" required>
                        <label class="form-check-label" for="memberKind1">
                          公司負責人
                        </label>
                      </div>
                    </div>

                    <div class="form-text">後續步驟將請您提供負責人相關資料</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="memberKind" id="memberKind2" value="person"
                          v-model="memberKind" @change="memberKindChange" required>
                        <label class="form-check-label" for="memberKind2">
                          公司職員
                        </label>
                      </div>
                    </div>
                    <div class="form-text">後續步驟僅需要提供聯繫資料</div>
                  </div>
                </div>

                <div class="btn-group btn-group-lg d-flex w-75 mx-auto mt-4">
                  <router-link to="/" class="btn btn-primary">返回首頁</router-link>
                  <button type="button" class="btn btn-secondary btn-next" :disabled="identityDisabled"
                    @click="goToTab('service')">下一步</button>
                </div>
              </div>
              <div class="tab-pane fade" id="service" role="tabpanel" aria-labelledby="service-tab" tabindex="0">
                <div class="row gx-0 gy-3 gy-lg-4 mb-4">
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="serviceCheck1" id="serviceCheck1"
                          v-model="serviceCheck1" required>
                        <a class="form-check-label" data-bs-toggle="collapse" href="#serviceCollapse1" role="button"
                          aria-expanded="false" aria-controls="serviceCollapse1">
                          個資法告知義務事項
                        </a>
                        <div class="collapse mt-1 show" id="serviceCollapse1">
                          <div class="card card-body">
                            <p class="fs-7 mb-0">
                              普慧科技網站（以下簡稱本網站）尊重並保護您的隱私權。為了幫助您瞭解本會如何蒐集、處理及利用您的個人資料，請務必詳細閱讀本會的「隱私權聲明」。<br />
                              壹、適用範圍<br />
                              一、本會「隱私權聲明」適用於您與本會洽辦業務、參與各項活動（如報名研討會/課程、加入網站會員、訂閱電子報等）或透過電話、傳真或本會網站意見信箱提出詢問或建議時（包括本會官網及本會各業務部門網站），所涉及之個人資料蒐集、處理與利用行為。<br />
                              二、凡經由本會網站連結至第三方獨立管理、經營之網站，有關個人資料的保護，適用第三方或各該網站的隱私權政策，本會不負任何連帶責任。<br />
                              貳、個人資料之蒐集、處理及利用<br />
                              一、當您與本會洽辦業務或參與本會各項活動，我們將視業務或活動性質請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本會不會將個人資料用於其他用途。<br />
                              二、如果您使用電話、傳真或本會網站意見信箱與本會聯繫時，請您提供正確的電話、傳真號碼或電子信箱地址，作為回覆來詢事項之用。<br />
                              三、您的個人資料在處理過程中，本會將遵守相關之流程及內部作業規範，並依據資訊安全之要求，進行必要之人員控管。<br />
                              四、單純瀏覽本會網站及下載檔案之行為，本會不會蒐集任何與個人身分有關之資訊。
                              參、與第三人共用個人資料<br />
                              一、本會絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。<br />
                              二、前項但書之情形包括不限於 1. 配合司法單位合法的調查。<br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-if="memberKind === 'company'">
                    <div class="input-group input-group-lg">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="serviceCheck2" id="serviceCheck2"
                          v-model="serviceCheck2" required>
                        <label class="form-check-label">
                          下載 <a class="download" href="#" download>會員紙本合約</a> -
                          需請列印寄回或拍照上傳
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="btn-group btn-group-lg d-flex w-75 mx-auto mt-4">
                  <button type="button" class="btn btn-primary btn-prev" @click="goToTab('identity')">上一步</button>
                  <button type="button" class="btn btn-secondary btn-next" @click="goToTab('verify')">下一步</button>
                </div>
              </div>
              <div class="tab-pane fade" id="verify" role="tabpanel" aria-labelledby="verify-tab" tabindex="0">
                <div class="row gx-0 gy-3 gy-lg-4 mb-4">
                  <div class="col-12">
                    <div class="row gy-2">
                      <div class="col-12">
                        <div class="input-group input-group-lg">
                          <span class="input-group-text"><i class="bi bi-phone opacity-50"></i></span>
                          <input type="text" name="phoneNumber" class="form-control" placeholder="手機號碼"
                            v-model="phoneNumber" required>
                          <button class="btn btn-primary" type="button" @click="showPhoneNumberVerify">手機驗證</button>
                        </div>
                        <div class="form-text">請輸入手機號碼，並需要請您接收驗證碼</div>
                      </div>
                      <div class="col-12">
                        <div class="input-group input-group-lg" :class="{ 'opacity-50': phoneNumberVerifyDisable }">
                          <span class="input-group-text"><i class="bi bi-file-check opacity-50"></i></span>
                          <input type="email" class="form-control" placeholder="手機驗證碼" :readonly="phoneNumberVerifyDisable" v-model="phoneNumberVerify"
                            required>
                        </div>
                        <div class="form-text">請您輸入寄送到手機的簡訊驗證碼</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row gy-2">
                      <div class="col-12">
                        <div class="input-group input-group-lg">
                          <span class="input-group-text"><i class="bi bi-envelope opacity-50"></i></span>
                          <input type="email" class="form-control" placeholder="Email 信箱" v-model="account" required>
                          <button class="btn btn-primary" type="button" @click="showAccountVerify">信箱驗證</button>
                        </div>
                        <div class="form-text">請您填寫信箱驗證，不可使用Gmail與其他免費信箱</div>
                      </div>
                      <div class="col-12">
                        <div class="input-group input-group-lg" :class="{ 'opacity-50': accountVerifyDisable }">
                          <span class="input-group-text"><i class="bi bi-envelope-check opacity-50"></i></span>
                          <input type="email" class="form-control" placeholder="Email 信箱驗證碼" :readonly="accountVerifyDisable" v-model="accountVerify"
                            required>
                        </div>
                        <div class="form-text">請您輸入寄送到信箱的信件驗證碼</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn-group btn-group-lg d-flex w-75 mx-auto mt-4">
                  <button type="button" class="btn btn-primary btn-prev" @click="goToTab('service')">上一步</button>
                  <button type="button" class="btn btn-secondary btn-next" @click="goToTab('accountFile')"
                    :disabled="verifyDisabled">下一步</button>
                </div>
              </div>
              <div class="tab-pane fade" id="accountFile" role="tabpanel" aria-labelledby="accountFile-tab"
                tabindex="0">
                <div class="row gx-0 gy-3 gy-lg-4 mb-4">
                  <div class="col-12" v-if="memberKind === 'person'">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-shield-shaded opacity-50"></i></span>
                      <input type="text" class="form-control" placeholder="公司統編" v-model="taxId" required>
                    </div>
                    <div class="form-text">請輸入公司統編</div>
                  </div>
                  <div class="col-12" v-if="memberKind === 'person'">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-envelope opacity-50"></i></span>
                      <input type="email" class="form-control" placeholder="Email 信箱" v-model="companyMail" required>
                    </div>
                    <div class="form-text">請您填寫公司信箱，不可使用Gmail與其他免費信箱</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-lock opacity-50"></i></span>
                      <input ref="passwordCheckInput" :type="passwordVisibility[0] ? 'text' : 'password'"
                        class="form-control" placeholder="密碼" v-model="password" required>
                      <button type="button" class="btn btn-light" @click="togglePasswordVisibility(0)"
                        v-html="passwordVisibilityIcon(0)"></button>
                    </div>
                    <div class="form-text">密碼需由英文大小寫、數字、符號組成，總長度至少8碼</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-lock opacity-50"></i></span>
                      <input ref="passwordCheckInput" :type="passwordVisibility[1] ? 'text' : 'password'"
                        class="form-control" placeholder="密碼確認" v-model="passwordCheck" required>
                      <button type="button" class="btn btn-light" @click="togglePasswordVisibility(1)"
                        v-html="passwordVisibilityIcon(1)"></button>
                    </div>
                    <div class="form-text">請再次輸入密碼，確保密碼正確</div>
                    <div class="form-text text-danger" v-if="passwordMismatch">密碼不一致</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-person opacity-50"></i></span>
                      <input type="text" class="form-control" placeholder="姓名" v-model="userName" required>
                    </div>
                    <div class="form-text">請您填寫中文全名</div>
                  </div>
                  <div class="col-12">
                    <div class="row gx-2 gy-3">
                      <div class="col-12 col-lg-7">
                        <div class="input-group input-group-lg">
                          <span class="input-group-text"><i class="bi bi-telephone opacity-50"></i></span>
                          <input type="text" class="form-control" placeholder="公司電話" v-model="companyNumber" required>
                        </div>
                      </div>
                      <div class="col-12 col-lg-5">
                        <div class="input-group input-group-lg">
                          <span class="input-group-text"><i class="bi bi-hash opacity-50"></i></span>
                          <input type="text" class="form-control" placeholder="分機" v-model="companyNumberExtension"
                            required>
                        </div>
                      </div>
                    </div>
                    <div class="form-text">請輸入您的聯繫電話與分機號碼</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-person-vcard opacity-50"></i></span>
                      <select class="form-select" v-model="job" required>
                        <option value="" selected disabled>職稱</option>
                        <option value="1">員工</option>
                        <option value="2">組長</option>
                        <option value="3">經理</option>
                        <option value="4">副總經理</option>
                        <option value="5">總經理</option>
                      </select>
                    </div>
                    <div class="form-text">請選擇您的職稱</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-heart opacity-50"></i></span>
                      <select class="form-select" v-model="gender" required>
                        <option value="" selected disabled>性別</option>
                        <option value="male">男性</option>
                        <option value="female">女性</option>
                        <option value="other">其他</option>
                      </select>
                    </div>
                    <div class="form-text">請選擇您的性別</div>
                  </div>
                </div>
                <div class="btn-group btn-group-lg d-flex w-75 mx-auto mt-4" v-if="memberKind === 'company'">
                  <button type="button" class="btn btn-primary btn-prev" @click="goToTab('verify')">上一步</button>
                  <button type="button" class="btn btn-secondary btn-next" @click="goToTab('company')">下一步</button>
                </div>
                <div class="btn-group btn-group-lg d-flex w-75 mx-auto mt-4" v-if="memberKind !== 'company'">
                  <button type="button" class="btn btn-primary btn-prev" @click="goToTab('verify')">上一步</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                    data-bs-target="#registerSuccessModal" @click="submitForm">送件申請</button>
                </div>
              </div>
              <div class="tab-pane fade" id="company" v-if="memberKind === 'company'" role="tabpanel"
                aria-labelledby="company-tab" tabindex="0">
                <div class="row gx-0 gy-3 gy-lg-4 mb-4">
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <div class="form-check ps-4">
                        <a class="form-check-label collapsed" data-bs-toggle="collapse" href="#categoriesCollapse"
                          role="button" aria-expanded="false" aria-controls="categoriesCollapse">
                          公司分類<el-tag class="ms-1" effect="dark" round type="primary" v-if="totalSelected > 0">
                            {{ totalSelected }}</el-tag>
                        </a>
                        <div class="collapse mt-2" id="categoriesCollapse">
                          <div class="card card-body h-auto p-0">
                            <el-tabs v-model="activeTab" type="card" class="tab-primary tab-pills">
                              <el-tab-pane v-for="(category, index) in categories" :key="index"
                                :label="category.category" :name="String(index)">
                                <template #label>
                                  {{ category.category }}
                                  <el-tag class="small ms-1" :effect="'plain'" round type="primary"
                                    v-if="selectedCount(category) > 0">
                                    {{ selectedCount(category) }}</el-tag>
                                </template>
                                <div class="checkbox-grid">
                                  <el-checkbox v-for="(item, i) in category.items" :key="i" v-model="item.selected"
                                    :label="item.option">
                                    {{ item.option }}
                                  </el-checkbox>
                                </div>
                              </el-tab-pane>
                            </el-tabs>
                          </div>
                        </div>
                      </div>
                      <div class="form-text">請選擇公司分類</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-shield-shaded opacity-50"></i></span>
                      <input type="text" class="form-control" placeholder="公司統編" v-model="taxId" required>
                    </div>
                    <div class="form-text">請輸入公司統編</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-buildings opacity-50"></i></span>
                      <input type="text" class="form-control" placeholder="公司抬頭" v-model="companyName" required>
                    </div>
                    <div class="form-text">請輸入公司抬頭</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-printer opacity-50"></i></span>
                      <input type="text" class="form-control" placeholder="公司傳真" v-model="companyFax" required>
                    </div>
                    <div class="form-text">請輸入公司傳真號碼</div>
                  </div>
                  <div class="col-12">
                    <div class="row gx-2 gy-2">
                      <div class="col-12 col-md-6 col-lg-auto">
                        <select class="form-select form-select-lg" aria-label="縣市" v-model="companyCity" required>
                          <option value="" selected disabled>縣市</option>
                          <option v-for="city in cities" :value="city" :key="city">{{ city }}</option>
                        </select>
                      </div>
                      <div class="col-12 col-md-6 col-lg-auto">
                        <select class="form-select form-select-lg" aria-label="鄉鎮區" v-model="companyArea" required>
                          <option value="" selected disabled>地區</option>
                          <option v-for="area in areasByCity[companyCity]" :value="area" :key="area">{{ area }}
                          </option>
                        </select>
                      </div>
                      <div class="col-12 col-lg">
                        <input type="text" class="form-control form-control-lg" placeholder="地址"
                          v-model="companyAddress" required>
                      </div>
                    </div>
                    <div class="form-text">請輸入公司聯絡地址</div>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-lg">
                      <span class="input-group-text"><i class="bi bi-person opacity-50"></i></span>
                      <input type="text" class="form-control" placeholder="負責人姓名" v-model="principalName" required>
                    </div>
                    <div class="form-text">請輸入負責人全名</div>
                  </div>
                </div>
                <div class="btn-group btn-group-lg d-flex w-75 mx-auto mt-4">
                  <button type="button" class="btn btn-primary btn-prev" @click="goToTab('accountFile')">上一步</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                    data-bs-target="#registerSuccessModal" @click="submitForm">送件申請</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="registerSuccessModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="registerSuccessModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body p-4 p-lg-5">
          <div class="modal-icon success"><i class="bi bi-check-circle"></i></div>
          <div class="h3 text-center text-black">送件完成！</div>
          <button v-on:click="navigateToHomePage" class="btn btn-secondary btn-lg w-75 d-block mx-auto"
            data-bs-dismiss="modal">開始登入</button>
          <div class="overflow-hidden my-2 my-lg-3 py-1 py-lg-2">
            <h5 class="card-title title-deco text-black d-table mx-auto mb-0">貼心提醒</h5>
          </div>
          <p class="card-text fs-7 mb-0">目前仍在審核資料中，我們會儘速確認您的資料申請，目前審核中仍可以瀏覽網站。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'memberRegister',

  mounted() {
    this.loadCityAndAreaData();
  },

  data() {
    return {
      memberKind: '',
      serviceCheck1: false,
      serviceCheck2: false,
      phoneNumber: '',
      account: '',
      phoneNumberVerifyDisable: true,
      accountVerifyDisable: true,
      phoneNumberVerify: '',
      accountVerify: '',
      password: '',
      passwordCheck: '',
      passwordVisibility: [false, false], // 每個密碼輸入框的可見性，預設都是隱藏的
      userName: '',
      companyNumber: '',
      companyNumberExtension: '',
      job: '',
      gender: '',
      taxId: '',
      companyName: '',
      companyMail: '',
      companyFax: '',
      companyCity: '',
      companyArea: '',
      companyAddress: '',
      principalName: '',
      cities: [],
      areasByCity: {},
      categories: [
        {
          "category": "生活支援", items: [
            { option: "陪伴/居家照顧服務", selected: false },
            { option: "居家家事清潔服務", selected: false },
            { option: "居家修繕服務", selected: false },
            { option: "送藥服務", selected: false },
            { option: "輔具租賃服務", selected: false },
            { option: "相關場域服務(高齡住宅/日照中心)", selected: false },
            { option: "銀髮零售通路", selected: false },
            { option: "長照服務與資訊系統", selected: false },
          ]
        },
        {
          "category": "健康賦能", items: [
            { option: "健康賦能1", selected: false },
            { option: "健康賦能2", selected: false },
            { option: "健康賦能3", selected: false },
            { option: "健康賦能4", selected: false },
            { option: "健康賦能5", selected: false },
            { option: "健康賦能6", selected: false },
            { option: "健康賦能7", selected: false },
            { option: "健康賦能8", selected: false },
          ]
        },
        {
          "category": "高齡食品", items: [
            { option: "高齡食品1", selected: false },
            { option: "高齡食品2", selected: false },
            { option: "高齡食品3", selected: false },
            { option: "高齡食品4", selected: false },
            { option: "高齡食品5", selected: false },
            { option: "高齡食品6", selected: false },
            { option: "高齡食品7", selected: false },
            { option: "高齡食品8", selected: false },
          ]
        },
        {
          "category": "居家安全", items: [
            { option: "居家安全1", selected: false },
            { option: "居家安全2", selected: false },
            { option: "居家安全3", selected: false },
            { option: "居家安全4", selected: false },
            { option: "居家安全5", selected: false },
            { option: "居家安全6", selected: false },
            { option: "居家安全7", selected: false },
            { option: "居家安全8", selected: false },
          ]
        },
        {
          "category": "休閒旅遊", items: [
            { option: "休閒旅遊1", selected: false },
            { option: "休閒旅遊2", selected: false },
            { option: "休閒旅遊3", selected: false },
            { option: "休閒旅遊4", selected: false },
            { option: "休閒旅遊5", selected: false },
            { option: "休閒旅遊6", selected: false },
            { option: "休閒旅遊7", selected: false },
            { option: "休閒旅遊8", selected: false },
          ]
        },
        {
          "category": "高齡衣飾", items: [
            { option: "高齡衣飾1", selected: false },
            { option: "高齡衣飾2", selected: false },
            { option: "高齡衣飾3", selected: false },
            { option: "高齡衣飾4", selected: false },
            { option: "高齡衣飾5", selected: false },
            { option: "高齡衣飾6", selected: false },
            { option: "高齡衣飾7", selected: false },
            { option: "高齡衣飾8", selected: false },
          ]
        },
        {
          "category": "交通無礙", items: [
            { option: "交通無礙1", selected: false },
            { option: "交通無礙2", selected: false },
            { option: "交通無礙3", selected: false },
            { option: "交通無礙4", selected: false },
            { option: "交通無礙5", selected: false },
            { option: "交通無礙6", selected: false },
            { option: "交通無礙7", selected: false },
            { option: "交通無礙8", selected: false },
          ]
        },
        {
          "category": "課程活動", items: [
            { option: "課程活動1", selected: false },
            { option: "課程活動2", selected: false },
            { option: "課程活動3", selected: false },
            { option: "課程活動4", selected: false },
            { option: "課程活動5", selected: false },
            { option: "課程活動6", selected: false },
            { option: "課程活動7", selected: false },
            { option: "課程活動8", selected: false },
          ]
        },
      ],
      activeNames: ['1'],
      activeTab: '0',
    }
  },

  computed: {
    totalSelected() {
      return this.categories.reduce((total, category) => {
        return total + this.selectedCount(category);
      }, 0);
    },

    identityDisabled() {
      return this.memberKind === '';
    },

    serviceDisabled() {
      return !this.serviceCheck1 || (this.serviceCheck1 && !this.serviceCheck2);
    },

    verifyDisabled() {
      return this.accountVerify === '' || this.phoneNumberVerify === '';
    },

    passwordMismatch() {
      return this.password !== this.passwordCheck;
    }
  },

  methods: {
    selectedCount(category) {
      return category.items.filter(item => item.selected).length;
    },

    goToTab(tabId) {
      document.querySelector(`#${tabId}-tab`).click();
    },

    navigateToHomePage() {
      this.$router.push('/')
    },

    showPhoneNumberVerify() {
      if (this.phoneNumber !== '') {
        this.phoneNumberVerifyDisable = false;
      }
    },
    showAccountVerify() {
      if (this.account !== '') {
        this.accountVerifyDisable = false;
      }
    },

    memberKindChange() {
      // 重設所有數據為原始值
      this.serviceCheck1 = false;
      this.serviceCheck2 = false;
      this.phoneNumber = '';
      this.account = '';
      this.phoneNumberVerifyDisable = true;
      this.accountVerifyDisable = true;
      this.phoneNumberVerify = '';
      this.accountVerify = '';
      this.password = '';
      this.passwordCheck = '';
      this.passwordVisibility = [false, false]; // 每個密碼輸入框的可見性，預設都是隱藏的
      this.userName = '';
      this.companyNumber = '';
      this.companyNumberExtension = '';
      this.job = '';
      this.gender = '';
      this.taxId = '';
      this.companyName = '';
      this.companyMail = '';
      this.companyFax = '';
      this.companyCity = '';
      this.companyArea = '';
      this.companyAddress = '';
      this.principalName = '';

    },

    togglePasswordVisibility(index) {
      // 切換密碼顯示類型
      this.passwordVisibility[index] = !this.passwordVisibility[index];
    },

    async loadCityAndAreaData() {
      try {
        // 發送網絡請求以加載JSON檔案
        const response = await fetch('../json/CityCountyData.json');
        // 將JSON檔案轉換為JavaScript對象
        const data = await response.json();
        // 從獲取的數據中設置城市和區域
        this.cities = data.map(city => city.CityName);
        this.areasByCity = data.reduce((acc, city) => {
          acc[city.CityName] = city.AreaList.map(area => area.AreaName);
          return acc;
        }, {});
      } catch (error) {
        console.error('Error loading:', error);
      }
    },

    passwordVisibilityIcon(index) {
      // 返回對應的 Bootstrap Icon 字符串
      return this.passwordVisibility[index] ? '<i class="bi bi-eye-slash"></i>' : '<i class="bi bi-eye"></i>';
    },

    submitForm() {
      console.dir(this.$data); // 使用 console.dir 將整個 data 對象打印到控制台
    },
  }
}

</script>