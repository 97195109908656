<template>
    <div>
        <h1>頁面無法連結</h1>
        <p>
            請返回首頁繼續進行瀏覽:
            <router-link :to="{ name: 'Home' }" class="btn btn-primary">首頁</router-link>
        </p>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
};
</script>