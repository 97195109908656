<template>
  <div class="modal fade" id="memberLoginModal" tabindex="-1" aria-labelledby="memberLoginModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
        <div class="modal-body p-4 p-lg-5">
          <h5 class="card-title text-black fs-4 fw-bold text-center mb-4">會員登入</h5>
          <div class="row gx-0 gy-3 gy-lg-4 mb-4">
            <div class="col-12">
              <div class="input-group input-group-lg">
                <span class="input-group-text"><i class="bi bi-envelope opacity-50"></i></span>
                <input type="text" class="form-control" id="account" v-model="account" placeholder="請輸入帳號(Email)"
                  required="">
              </div>
            </div>
            <div class="col-12">
              <div class="input-group input-group-lg">
                <span class="input-group-text"><i class="bi bi-lock opacity-50"></i></span>
                <input type="password" class="form-control" id="password" v-model="password" placeholder="請輸入密碼"
                  maxlength="32">
              </div>
            </div>
          </div>

          <div class="btn-group btn-group-lg d-flex w-75 mx-auto">
            <a class="btn btn-secondary rounded-1" href="#">登入</a>
          </div>

          <div class="overflow-hidden my-2 my-lg-3 py-1 py-lg-2">
            <h5 class="card-title title-deco text-black d-table mx-auto mb-0">還沒註冊嗎?</h5>
          </div>
          <p class="card-text fs-7">加入廠商會員需請您提供公司與負責人相關資料，詳閱並同意「服務條款」、「免責聲明」、「隱私權聲明」等所載內容及其意義</p>

          <div class="btn-group btn-group-lg d-flex w-75 mx-auto mt-4">
            <button v-on:click="navigateToRegisterPage" class="btn btn-primary rounded-1"
              data-bs-dismiss="modal">申請註冊為廠商</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'memberLogin',

  data() {
    return {
      account: '',
      password: '',
    }
  },

  methods: {
    navigateToRegisterPage() {
      this.$router.push('/member/register')
    }
  }
}
</script>