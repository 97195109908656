import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/home.vue'
import memberRegister from '@/views/member/register.vue';
import NotFound from '@/views/NotFound.vue';

// Define route components.
// These can be imported from other files
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首頁' // 首頁的標題
    }
  },
  {
    path: '/member/register',
    name: 'register',
    component: memberRegister,
    meta: {
      bodyClasses: ['member', 'fullPanel', 'register'], // 將這些 class 添加到 body 中
      title: '會員註冊'
    }
  },
  // other routes...
  // { path: '/', redirect: '/step1' }, // Redirect root path to '/step1'
  // { path: '/:catchAll(.*)', redirect: '/step1' } // Redirect any unmatched routes to '/step1'
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      bodyClasses: ['notfound'], // 將這些 class 添加到 body 中
      title: '404 找不到頁面'
    }
  }
];

// Create the router instance and pass the `routes` option
const router = createRouter({
  // Use the HTML5 history mode
  history: createWebHistory(process.env.BASE_URL),
  routes, // short for `routes: routes`
});

export default router;
